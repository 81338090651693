<template>
  <div class="fs-field" :class="outer_class">
    <label>{{ label }}</label>
    <div>
      <form-list :value="value"
             ref="formlist"
             @input="$emit('input', $event)"
             row_class="form_list_container"
             :default="row_default">

        <template slot="row" slot-scope="{ row, index, onchange }">
          <div v-for="cell_schema in computedRowSchema" class="form_list_row">
            <component
              :is="getComponent(cell_schema)"
              :value="row[cell_schema.key]"
              @input="update(row, index, cell_schema, $event)"
              v-bind:fsLocation="$attrs.fsLocation + '.' + index + '.' + cell_schema.key"
              v-bind="cell_schema">
            </component>
          </div>
        </template>
      </form-list>
    </div>
  </div>
</template>
<script>
import FormList from '@/components/shared/FormList'
import FSTextInput from './FSTextInput'
import FSSelectInput from './FSSelectInput'
import FSTextarea from './FSTextarea'
import FSCustomInput from './FSCustomInput'
import FSDatepicker from './FSDatepicker'
import _ from 'lodash'
// import { extendSchemaWithInjected } from '@/components/shared/form-schema/FSInjectorMixin'
import { ref, computed } from '@vue/composition-api'
import { unwrapReactiveSchema } from './FSReactiveSchemaUnwrapper'

export default {
  name: 'FSGrid',
  props: {
    label: String,
    outer_class: String,
    value: [Array, Object],
    row_default: [Function, Object, Array],
    row_schema: Array
  },
  setup (props, context) {
    const formlist = ref(null) // See template

    const computedRowSchema = computed(() => unwrapReactiveSchema(props.row_schema))

    const componentMap = {
      text: FSTextInput,
      number: FSTextInput,
      select: FSSelectInput,
      textarea: FSTextarea,
      datepicker: FSDatepicker,
      'custom-input': FSCustomInput
    }

    const getComponent = f => _.get(componentMap, f.type, f.component)

    const update = (row, index, cellSchema, eventData) => {
      row[cellSchema.key] = eventData // Note vue reactivity limitations
      formlist.value.onChange()
    }

    return {
      computedRowSchema,
      getComponent,
      update,
      formlist
    }
  },
  components: {
    'form-list': FormList
  }
}
</script>
<style scoped>
/* TODO fix this relative path */
</style>
