<template>
  <ConfigEditor
    :form-schema="formSchema"
    :form-data="formData"
    @save-config="saveConfig()"
    :config-key="configKey">
  </ConfigEditor>
</template>
<script>
import router from '@/router'
import FSFormList from '@/components/shared/form-schema/input-components/FSFormList'
import { reactive, computed, ref } from '@vue/composition-api'
import { saveConfiguration, loadConfiguration } from './util.js'
import ConfigEditor from './ConfigEditor'
import _ from 'lodash'

function LocationGroup () {
  this.label = ''
  this.locations = []
}

function LocationDef () {
  this.label = null
  this.location = null
}

export default {
  name: 'LocationConfig',
  setup (props, { root }) {
    const showRaw = ref(false)

    const configKey = `issue_tracker_location_choices_${router.currentRoute.params.hospital}`

    const formSchema = reactive([
      {
        type: 'custom-input',
        component: FSFormList,
        label: 'Location Configuration',
        key: 'locations',
        row_schema: [
          {
            type: 'text',
            label: 'Location Group',
            key: 'label',
            placeholder: 'example: Third Floor'
          },
          {
            type: 'custom-input',
            component: FSFormList,
            label: 'Locations',
            key: 'locations',
            row_schema: [
              {
                label: 'Location',
                type: 'text',
                key: 'label',
                placeholder: 'example: Deluxe Ward'
              }
            ],
            row_default: () => new LocationDef()
          }
        ],
        row_default: () => new LocationGroup()
      }
    ])

    const formData = reactive({
      locations: []
    })

    const locationConfig = computed(() => {
      return formData.locations.map(locationGroup => {
        return {
          label: locationGroup.label,
          locations: locationGroup.locations.map(locationEntry => {
            return {
              location: `${_.snakeCase(locationGroup.label)}__${_.snakeCase(locationEntry.label)}`,
              label: locationEntry.label
            }
          })
        }
      })
    })

    const saveConfig = () => saveConfiguration(configKey, locationConfig.value)

    loadConfiguration(configKey).then(data => {
      formData.locations = data
    })

    return {
      formSchema,
      formData,
      configKey,
      locationConfig,
      saveConfig,
      showRaw
    }
  },
  components: {
    ConfigEditor
  }
}
</script>
