<template>
  <div slot="main">
    <div class="col-md-6">
      <FormSchema :schema="formSchema" :value="formData"></FormSchema>
    </div>
    <div class="mt-3">
      <button class="btn btn-sm btn-primary" v-on:click.prevent="save()">Save</button>
      <button class="btn btn-sm btn-link" v-on:click.prevent="showRaw = !showRaw">Raw Config</button>
      <pre v-if="showRaw" class="bg-light p-2">
        <slot name="raw_config">
        {{ formData }}
        </slot>
      </pre>
    </div>
  </div>
</template>
<script>
import { ref } from '@vue/composition-api'
import FormSchema from '@/components/shared/form-schema/FormSchema'

export default {
  name: 'AppConfig',
  props: {
    formSchema: Array,
    formData: [Array, Object],
    configKey: String
  },
  setup (props, { root, emit }) {
    const showRaw = ref(false)

    const save = () => emit('save-config')

    return {
      showRaw,
      save
    }
  },
  components: {
    FormSchema
  }
}
</script>
<style scoped>
/deep/ .form_list_row {
  padding: 2px 40px;
}

/deep/ .form_list_container {
  border-radius: 10px;
  position: relative;
  border: 2px solid #009688;
  box-shadow: 8px 8px 0px 0px #009688;
  padding: 10px 5px;
  margin: 28px 2px;
}

/deep/ .form_list_container .action_container {
  position: absolute;
  top: 2px;
  right: 2px;
}
</style>
