<template>
  <div slot="main">
    <h4>Configuration Values</h4>
    <div class='mb-2'>
      <router-link
        :to="{name: 'EmailConfig', params: {hospital: hospitalCode}}"
        >Email Configuration</router-link>
    </div>
    <router-link
      :to="{name: 'LocationConfig', params: {hospital: hospitalCode}}"
      >Location Configuration</router-link>
  </div>
</template>
<script>
import router from '@/router'

export default {
  name: 'AppConfigIndex',
  setup () {
    const hospitalCode = router.currentRoute.params.hospital
    return {
      hospitalCode
    }
  }
}
</script>
