import axios from 'axios'
import axiosConfig from '@/common/axiosConfig'
import store from '@/store'

export const saveConfiguration = async (configKey, data) => {
  await axios.post('config/key/',
    {
      key: configKey,
      value: data
    }, axiosConfig)
  store.dispatch('display_notification', {
    message: 'Configuration saved successfully'
  })
}

export const loadConfiguration = async (configKey) => {
  const response = await axios.get(`config/key/${configKey}/`, axiosConfig)
  return response.data.value
}
