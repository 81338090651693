<template>
  <ConfigEditor
    :form-schema="formSchema"
    :form-data="formData"
    @save-config="saveConfig()"
    :config-key="configKey">
  </ConfigEditor>
</template>
<script>
import router from '@/router'
import FSFormList from '@/components/shared/form-schema/input-components/FSFormList'
import { reactive } from '@vue/composition-api'
import { saveConfiguration, loadConfiguration } from './util.js'
import ConfigEditor from './ConfigEditor'

function EmailConfigEntry () {
  this.location = ''
  this.frequency = 'daily'
  this.recipient = ''
  this.department = ''
}

export default {
  name: 'LocationConfig',
  setup (props, { root }) {
    const configKey = `issue_tracker_email_config_${router.currentRoute.params.hospital}`

    const formSchema = reactive([
      {
        type: 'custom-input',
        component: FSFormList,
        label: 'Email Configuration',
        key: 'config',
        row_schema: [
          {
            type: 'text',
            label: 'Location',
            key: 'label'
          },
          {
            type: 'select',
            label: 'Frequency',
            key: 'frequency',
            options: [
              { value: 'daily', label: 'daily' },
              { value: 'weekly', label: 'weekly' },
              { value: 'monthly', label: 'monthly' }
            ],
            validators: ['required']
          },
          {
            type: 'text',
            label: 'Recipient',
            key: 'recipient',
            validators: ['required']
          },
          {
            type: 'text',
            label: 'Department',
            key: 'department'
          }
        ],
        row_default: () => new EmailConfigEntry()
      }
    ])

    const formData = reactive({
      config: []
    })

    const saveConfig = () => saveConfiguration(configKey, formData.config)

    loadConfiguration(configKey).then(data => {
      formData.config = data
    })

    return {
      formSchema,
      formData,
      configKey,
      saveConfig
    }
  },
  components: {
    ConfigEditor
  }
}
</script>
