<template>
  <app-frame>
    <div slot='sidebar'>
      <li>
        <router-link class="nav-link" active-class="active" :to="{ name: 'CultureSearch' }">
          <i class="fa fa-fw fa-list"></i>Search</router-link>
      </li>
    </div>
    <template slot="main">
      <HospitalSwitcher></HospitalSwitcher>
      <router-view></router-view>
    </template>
  </app-frame>
</template>
<script>
import AppFrame from '@/components/AppFrame'
import HospitalSwitcher from '@/components/shared/HospitalSwitcher'
export default {
  name: 'Frame',
  components: {
    AppFrame,
    HospitalSwitcher
  }
}
</script>
